<template>
    <div class="group-bg-box">
        <div class=" ">
            <div class="about-item-box w-100p">
                <div class="about-item-group">集团概貌</div>
                <div class="about-item-swipers p-25">
                    <img src="@/assets/image/about/bg.png" class="w-100p">
                </div>
            </div>
            <div class="about-item-box w-100p m-t-20">
                <div class="about-item-group-title">
                    集团介绍
                    <div class="about-item-group-dir"></div>
                </div>
                <div class="about-item-group-desc">
                    <div>一秋集团致力于为各行业提供量身定制的软件开发方案和全方位的网络信息安全服务。为企业提供从公司注册、软件规划、软件设计、软件搭建、软件安全到软件运营等一站式服务。总部位于上海，在海南、广东、浙江、福建、吉林、辽宁等主要城市设立分公司。</div>
                    <div>自创始之日起一秋集团始终坚持“技术至上！服务至上！”的经营理念,以卓越的服务品质赢得客户口碑，用前沿的技术为企业保驾护航。集团通过不断提高核心技术创新能力，满足不同客户对系统的需求，形成完整的安全产品矩阵，帮助客户快速建站并实现闭环运营，让客户在新的全球化互联网环境中保持优势。</div>
                </div>
            </div>
        </div>
        <div class="m-t-20">
            <div class="about-item-box w-100p">
                <div class="about-item-group-title">
                    集团资质
                    <div class="about-item-group-dir"></div>
                </div>
                <div class="p-5">
                    <img src="@/assets/image/about/4-1.png" class="w-100p">
                </div>
            </div>
        </div>
        <div class="m-t-20">
             <el-row :gutter="24">
                <el-col :md="24" :lg="8" :xl="8">
                    <div class="about-item-box w-100p">
                        <div class="about-item-group-title">
                            技术实力
                            <div class="about-item-group-dir"></div>
                        </div>
                        <div class="about-item-group-desc">
                            <div>
                                自创始之日起一秋集团始终坚持“技术至上！服务至上！”的经营理念,以卓越的服务品质赢得客户口碑，用前沿的技术为企业保驾护航。集团通过不断提高核心技术创新能力，满足不同客户对系统的需求，形成完整的安全产品矩阵，帮助客户快速建站并实现闭环运营，让客户在新的全球化互联网环境中保持优势。
                            </div>
                        </div>
                        <div class="d-flex-around p-b-30">
                            <div>
                                <div class="bg-color f-26 font-weight-bold align-center m-b-10">974</div>
                                <img src="@/assets/image/about/5-1.png" class="w-80p display-block margin-auto">
                            </div>
                            <div>
                                <div class="bg-color f-26 font-weight-bold align-center m-b-10">1974</div>
                                <img src="@/assets/image/about/5-2.png" class="w-80p display-block margin-auto">
                            </div>
                            <div>
                                <div class="bg-color f-26 font-weight-bold align-center m-b-10">2974</div>
                                <img src="@/assets/image/about/5-3.png" class="w-80p display-block margin-auto">
                            </div>
                        </div>
                    </div>
                </el-col>
                <el-col :md="24" :lg="16" :xl="16">
                    <div class="about-item-box w-100p m-t-20">
                        <div class="about-item-group">软著证书</div>
                        <div class="about-item-swipers p-25">
                            <el-carousel :interval="4000" type="card" height="260px">
                                <el-carousel-item v-for="item in 4" :key="item">
                                    <div class="w-100p h-260 overflot-h">
                                        <img src="@/assets/image/about/2-1.png" class="w-70p m-l-15p m-r-15p">
                                    </div>
                                </el-carousel-item>
                            </el-carousel>
                        </div>
                    </div>
                </el-col>
            </el-row>
        </div>
        <div class="m-t-20">
            <div class="about-item-box w-100p">
                <div class="about-item-group-title">
                    客户感谢
                    <div class="about-item-group-dir"></div>
                </div>
                <div class="p-20">
                     <img src="@/assets/image/about/new-1.png" class="w-20p">
                     <img src="@/assets/image/about/new-2.png" class="w-20p">
                     <img src="@/assets/image/about/3-3.png" class="w-20p">
                     <img src="@/assets/image/about/new-4.png" class="w-20p">
                     <img src="@/assets/image/about/3-5.png" class="w-20p">
                     <img src="@/assets/image/about/3-6.png" class="w-20p">
                     <img src="@/assets/image/about/new-3.png" class="w-20p">
                     <img src="@/assets/image/about/3-8.png" class="w-20p">
                     <img src="@/assets/image/about/3-9.png" class="w-20p">
                     <img src="@/assets/image/about/3-11.png" class="w-20p">
                </div>
            </div>
        </div>
        <div class="w-100p h-180"></div>
    </div>
</template>
<style>
.el-carousel__item--card.is-in-stage.is-hover .el-carousel__mask, .el-carousel__item--card.is-in-stage:hover .el-carousel__mask {
    opacity: 1 !important;
}
.el-carousel__mask {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background:none !important;
    opacity: .24;
    transition: var(--el-transition-duration-fast);
}
</style>
